import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import Carousel from "../components/carousel"

const Test = () => {
  return (
    <div className='bg-white w-screen h-screen'>
      <StaticImage
        alt=''
        src='../images/slots/ForbiddenDragons.png'
        width={500}
      />
    </div>
  )
}

export default Test
